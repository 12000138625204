<div class="container">

  <div class="row" *ngIf="!loading && !error && !complete">
    <div class="col-8 offset-2">
      <img class="mt-4 mb-4" [src]="logo" alt="" height="72">
        <h1 class="h3 mb-3 font-weight-normal">App Store Tester Tool (ASTT)</h1>
        
        <div *ngIf="appData.type == 'ios'">
          
          <div class="form-group mb-3">
            <strong>App Name</strong>
            <input class="form-control" type="text" name="app-id" autocomplete="app-id" spellcheck="false" readonly [value]="appData.name">
          </div>

          <div class="form-group mb-3">
            <strong>App ID</strong>
            <input class="form-control" type="text" name="app-id" autocomplete="app-id" spellcheck="false" readonly [value]="appData.data.appID">
          </div>

          <div class="form-group mb-3">
            <strong>Bundle ID</strong>
            <input class="form-control" type="text" name="bundle-id" autocomplete="bundle-id" spellcheck="false" readonly [value]="appData.data.bundleID">
          </div>

          <div class="form-group mb-3">
            <strong>Version</strong>
            <input class="form-control" type="text" name="bundle-id" autocomplete="bundle-id" spellcheck="false" readonly [value]="appData.data.version">
          </div>

        </div>

        <div class="mb-3">
          <div class="alert alert-info">
            <strong>Notice</strong><br><br>
            In order to test our app with full capabilites please enter your mobile phone number below. This will associate your phone number with an active full featured account. Once you have submitted your phone number you may log in to the app. Your number will be removed from the account after 24 hours of being associated.
          </div>
        </div>

        <div class="form-group mb-3">
          <strong>Phone Number</strong>
          <p *ngIf="localError" style="color: darkred;">{{localError}}</p>
          <input [(ngModel)]="phoneNumber" class="form-control" [class.has-error]="localError" type="tel" name="tel" autocomplete="tel" mask="(000) 000-0000">
        </div>

        <button class="btn btn-lg btn-block btn-dark" (click)="submitUpdate()">Submit</button>
        <p class="mt-5 mb-3 text-muted">ApisKB Inc. Confidential Application</p>
    </div>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col-8 offset-2 text-center">
      <div><img class="mt-4 mb-4" [src]="logo" alt="" height="72"></div>
      <div><p>Loading, please wait...</p></div>
      <div><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>
    </div>
  </div>

  <div class="row" *ngIf="!loading && error">
    <div class="col-8 offset-2 text-center">
      <div><img class="mt-4 mb-4" [src]="logo" alt="" height="72"></div>
      <div class="alert alert-danger">
        <strong>Error:</strong> {{error}}
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!loading && complete">
    <div class="col-8 offset-2 text-center">
      <div><img class="mt-4 mb-4" [src]="logo" alt="" height="72"></div>
      <div class="alert alert-success">
        <strong>Success:</strong> Your phone number has been associated with the test account.
      </div>
    </div>
  </div>

</div>